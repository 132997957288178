<script>
import { useAI } from 'dashboard/composables/useAI';
import { useMessageFormatter } from 'shared/composables/useMessageFormatter';
import WootButton from '../ui/WootButton.vue';
import AILoader from './AILoader.vue';

export default {
  components: {
    AILoader,
    WootButton,
  },
  props: {
    aiOption: {
      type: String,
      required: true,
    },
  },
  emits: ['close', 'applyText'],
  setup() {
    const { formatMessage } = useMessageFormatter();
    const { draftMessage, processEvent, recordAnalytics, captureFeedback } =
      useAI();
    return {
      draftMessage,
      processEvent,
      recordAnalytics,
      formatMessage,
      captureFeedback,
    };
  },
  data() {
    return {
      generatedContent: '',
      isGenerating: true,
      feedback: '',
      intent: '',
    };
  },
  computed: {
    headerTitle() {
      const translationKey = this.aiOption?.toUpperCase();
      return translationKey
        ? this.$t(`INTEGRATION_SETTINGS.OPEN_AI.WITH_AI`, {
            option: this.$t(
              `INTEGRATION_SETTINGS.OPEN_AI.OPTIONS.${translationKey}`
            ),
          })
        : '';
    },
  },
  mounted() {
    this.generateAIContent(this.aiOption);
  },

  methods: {
    onClose() {
      this.$emit('close');
    },

    async generateAIContent(type = 'rephrase') {
      this.isGenerating = true;
      const { message, intent } = await this.processEvent(type);
      this.generatedContent = message;
      this.intent = intent;
      this.isGenerating = false;
    },
    applyText() {
      this.recordAnalytics(this.aiOption);
      this.$emit('applyText', this.generatedContent);
      this.captureFeedback(
        this.feedback || 'positive',
        this.intent,
        this.generatedContent
      );
      this.onClose();
    },
    setFeedback(feedback) {
      this.feedback = this.feedback !== feedback ? feedback : '';
    },
    regenerateContent() {
      this.captureFeedback(
        this.feedback || 'negative',
        this.intent,
        this.generatedContent
      );
      this.generateAIContent(this.aiOption);
      this.feedback = '';
    },
  },
};
</script>

<template>
  <div class="flex flex-col">
    <woot-modal-header :header-title="headerTitle" />
    <div class="flex flex-col w-full modal-content">
      <div v-if="draftMessage" class="w-full">
        <h4 class="mt-1 text-base text-slate-700 dark:text-slate-100">
          {{ $t('INTEGRATION_SETTINGS.OPEN_AI.ASSISTANCE_MODAL.DRAFT_TITLE') }}
        </h4>
        <p v-dompurify-html="formatMessage(draftMessage, false)" />
        <h4 class="mt-1 text-base text-slate-700 dark:text-slate-100">
          {{
            $t('INTEGRATION_SETTINGS.OPEN_AI.ASSISTANCE_MODAL.GENERATED_TITLE')
          }}
        </h4>
      </div>
      <div>
        <AILoader v-if="isGenerating" />
        <p v-else v-dompurify-html="formatMessage(generatedContent, false)" />
      </div>

      <div class="flex flex-row justify-end w-full gap-2 px-0 py-2">
        <WootButton
          v-tooltip.top-end="
            $t('INTEGRATION_SETTINGS.OPEN_AI.ASSISTANCE_MODAL.GOOD_RESPONSE')
          "
          :disabled="!generatedContent"
          color-scheme="secondary"
          variant="smooth"
          @click="setFeedback('positive')"
        >
          <fluent-icon
            icon="thumbs-up"
            :class="feedback === 'positive' ? 'text-green-500' : undefined"
            :type="feedback === 'positive' ? 'filled' : 'outline'"
            size="16"
          />
        </WootButton>
        <WootButton
          v-tooltip.top-end="
            $t('INTEGRATION_SETTINGS.OPEN_AI.ASSISTANCE_MODAL.BAD_RESPONSE')
          "
          :disabled="!generatedContent"
          color-scheme="secondary"
          variant="smooth"
          @click="setFeedback('negative')"
        >
          <fluent-icon
            icon="thumbs-down"
            :class="feedback === 'negative' ? 'text-red-500' : undefined"
            :type="feedback === 'negative' ? 'filled' : 'outline'"
            size="16"
          />
        </WootButton>
        <WootButton
          v-tooltip.top-end="
            $t(
              'INTEGRATION_SETTINGS.OPEN_AI.ASSISTANCE_MODAL.REGENERATE_SUGGESTION'
            )
          "
          :disabled="isGenerating"
          icon="refresh"
          color-scheme="secondary"
          variant="smooth"
          @click="regenerateContent"
        />
        <WootButton :disabled="!generatedContent" @click="applyText">
          {{
            $t('INTEGRATION_SETTINGS.OPEN_AI.ASSISTANCE_MODAL.BUTTONS.APPLY')
          }}
        </WootButton>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal-content {
  @apply pt-2 px-8 pb-8;
}

.container {
  width: 100%;
}
</style>
