<script setup>
import { computed } from 'vue';

import format from 'date-fns/format';
import getDay from 'date-fns/getDay';

import { getQuantileIntervals } from '@chatwoot/utils';

import { groupHeatmapByDay } from 'helpers/ReportsDataHelper';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  heatData: {
    type: Array,
    default: () => [],
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
});
const { t } = useI18n();
const processedData = computed(() => {
  return groupHeatmapByDay(props.heatData);
});

const totalByHourForWeek = computed(() =>
  Array.from({ length: 24 }, (_, index) => index).map(hour =>
    processedData.value
      .values()
      .reduce((sum, hourlyData) => sum + hourlyData[hour].value, 0)
  )
);

const totalByDay = computed(
  () =>
    new Map(
      processedData.value
        .entries()
        .map(([date, hourlyData]) => [
          date,
          hourlyData.reduce((sum, { value }) => sum + value, 0),
        ])
    )
);

const grandTotal = computed(() =>
  totalByHourForWeek.value.reduce((sum, totalForHour) => sum + totalForHour, 0)
);

const quantiles = [0.2, 0.4, 0.6, 0.8, 0.9, 0.99];

const globalQuantiles = computed(() =>
  getQuantileIntervals(
    props.heatData.map(data => data.value),
    quantiles
  )
);

function getCountTooltip(value) {
  if (!value) {
    return t('OVERVIEW_REPORTS.CONVERSATION_HEATMAP.NO_CONVERSATIONS');
  }

  if (value === 1) {
    return t('OVERVIEW_REPORTS.CONVERSATION_HEATMAP.CONVERSATION', {
      count: value,
    });
  }

  return t('OVERVIEW_REPORTS.CONVERSATION_HEATMAP.CONVERSATIONS', {
    count: value,
  });
}

function formatDate(dateString) {
  return format(new Date(dateString), 'MMM d, yyyy');
}

function getDayOfTheWeek(date) {
  const dayIndex = getDay(date);
  const days = [
    t('DAYS_OF_WEEK.SUNDAY'),
    t('DAYS_OF_WEEK.MONDAY'),
    t('DAYS_OF_WEEK.TUESDAY'),
    t('DAYS_OF_WEEK.WEDNESDAY'),
    t('DAYS_OF_WEEK.THURSDAY'),
    t('DAYS_OF_WEEK.FRIDAY'),
    t('DAYS_OF_WEEK.SATURDAY'),
  ];
  return days[dayIndex];
}
function getHeatmapLevelClass(value, quantileRange) {
  if (!value)
    return 'outline-slate-100 dark:outline-slate-700 dark:bg-slate-700/40 bg-slate-50/50';
  if (!quantileRange)
    return 'bg-woot-800 dark:bg-woot-500 dark:outline-woot-400/80';

  let level = [...quantileRange, Infinity].findIndex(
    range => value <= range && value > 0
  );

  if (level > 6) level = 5;

  if (level === 0) {
    return 'outline-slate-100 dark:outline-slate-700 dark:bg-slate-700/40 bg-slate-50/50';
  }

  const classes = [
    'bg-woot-50 dark:bg-woot-800/40 dark:outline-woot-800/80',
    'bg-woot-100 dark:bg-woot-800/30 dark:outline-woot-800/80',
    'bg-woot-200 dark:bg-woot-500/40 dark:outline-woot-700/80',
    'bg-woot-300 dark:bg-woot-500/60 dark:outline-woot-600/80',
    'bg-woot-600 dark:bg-woot-500/80 dark:outline-woot-500/80',
    'bg-woot-800 dark:bg-woot-500 dark:outline-woot-400/80',
  ];

  return classes[level - 1];
}
</script>

<template>
  <div
    class="grid relative w-full gap-x-4 gap-y-2.5 overflow-y-scroll md:overflow-visible grid-cols-[80px_1fr] min-h-72"
  >
    <template v-if="isLoading">
      <div class="grid gap-[8px] flex-shrink-0">
        <div
          v-for="day in 8"
          :key="day"
          class="w-full rounded-sm bg-slate-100 dark:bg-slate-900 animate-loader-pulse h-8 min-w-[70px]"
        />
      </div>
      <div class="grid gap-[8px] w-full min-w-[700px]">
        <div
          v-for="day in 8"
          :key="day"
          class="grid gap-[8px] grid-cols-[repeat(24,_1fr)]"
        >
          <div
            v-for="hour in 24"
            :key="hour"
            class="w-full h-8 rounded-sm bg-slate-100 dark:bg-slate-900 animate-loader-pulse"
          />
        </div>
      </div>
      <div />
      <div
        class="grid grid-cols-[repeat(25,_1fr)] gap-[2px] w-full text-[8px] font-semibold h-5 text-slate-800 dark:text-slate-200"
      >
        <div
          v-for="hour in 24"
          :key="hour"
          class="flex items-center justify-center"
        >
          {{ hour - 1 }} – {{ hour }}
        </div>
        <div
          class="flex items-center justify-center text-[10px] font-bold text-center"
        >
          {{ t('TOTAL_HOURS.GRAND_TOTAL') }}
        </div>
      </div>
    </template>
    <template v-else>
      <div class="grid gap-[8px] flex-shrink-0 grid-rows-[repeat(9,_1fr)]">
        <div
          v-for="dateKey in processedData.keys()"
          :key="dateKey"
          class="h-8 min-w-[70px] text-slate-800 dark:text-slate-200 text-[10px] font-semibold flex flex-col items-end justify-center"
        >
          {{ getDayOfTheWeek(new Date(dateKey)) }}
          <time class="font-normal text-slate-700 dark:text-slate-200">
            {{ formatDate(dateKey) }}
          </time>
        </div>
        <div
          class="h-8 min-w-[70px] text-slate-800 dark:text-slate-200 text-[10px] font-bold flex flex-col items-end justify-center text-center"
        >
          {{ t('TOTAL_HOURS.TOTAL_WEEK') }}
        </div>
      </div>
      <div class="grid gap-[8px] w-full min-w-[700px]">
        <div
          v-for="dateKey in processedData.keys()"
          :key="dateKey"
          class="grid gap-[8px] grid-cols-[repeat(25,_1fr)]"
        >
          <div
            v-for="data in processedData.get(dateKey)"
            :key="data.timestamp"
            v-tooltip.top="getCountTooltip(data.value)"
            class="h-8 rounded-sm shadow-inner dark:outline dark:outline-1 shadow-black"
            :class="getHeatmapLevelClass(data.value, globalQuantiles)"
          />
          <div
            class="h-8 rounded-sm shadow-inner outline-slate-100 text-[12px] dark:outline-slate-700 dark:bg-slate-700/40 bg-slate-50/50 shadow-black flex justify-center items-center"
          >
            {{ totalByDay.get(dateKey) }}
          </div>
        </div>
        <div class="grid gap-[8px] grid-cols-[repeat(25,_1fr)]">
          <div
            v-for="(total, hour) in totalByHourForWeek"
            :key="hour"
            class="h-8 rounded-sm shadow-inner outline-slate-100 text-[12px] dark:outline-slate-700 dark:bg-slate-700/40 bg-slate-50/50 shadow-black flex justify-center items-center"
          >
            {{ total }}
          </div>
          <div
            class="h-8 rounded-sm shadow-inner outline-slate-100 text-[12px] dark:outline-slate-700 dark:bg-slate-700/40 bg-slate-50/50 shadow-black flex justify-center items-center"
          >
            {{ grandTotal }}
          </div>
        </div>
        <div
          class="grid gap-[8px] grid-cols-[repeat(25,_1fr)] w-full text-[8px] font-semibold h-5 text-slate-800 dark:text-slate-200"
        >
          <div
            v-for="hour in 24"
            :key="hour"
            class="flex items-center justify-center"
          >
            {{ hour - 1 }} – {{ hour }}
          </div>
          <div
            class="flex items-center justify-center text-[10px] font-bold text-center"
          >
            {{ t('TOTAL_HOURS.GRAND_TOTAL') }}
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
